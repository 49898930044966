import React, {useEffect, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField} from "@material-ui/core";
import {TranscriptionFileTextGridText} from "../components/transcriptionFiles/TranscriptionFileTextGridText";
import {convertHMS} from "../utils/ConvertToHMS";
import {TranscriptionTextRepository} from "../repository/TranscriptionTextRepository";
import {toast} from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 500,
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1
};

export const EditTextItem = ({selectedTextItem, onClose}) => {
    const [textItem, setTextItem] = useState(null);
    const [selectedWord, setSelectedWord] = useState(null);

    useEffect(() => {
        // {...selectedTextItem} copy by reference
        setTextItem(JSON.parse(JSON.stringify(selectedTextItem)))
    }, [selectedTextItem])

    const onWordChange = (e) => {
        if (textItem && selectedWord) {
            const value = e.target.value;
            const words = textItem?.words;
            const wordItem = words?.find(word => word.id === selectedWord.id)
            if (wordItem) {

                const newWord = selectedWord;
                newWord.text = value;
                setSelectedWord(newWord);

                wordItem.text = value;
                setTextItem((prevState) => {
                    return {
                        ...prevState,
                        words: words
                    }
                })
            }
        }

    }

    const onSave = (e) => {
        e.preventDefault();
        if (textItem) {
            const speaker = textItem.speaker ? textItem.speaker : {id: null, name: null, coreId: null}
            const request = {
                id: textItem.id,
                text: textItem.words.map(word => word.text).reduce((prev, next) => prev + ' ' + next, '').trim(),
                label: textItem.label,
                score: textItem.score,
                speaker: speaker,
                words: textItem.words.map(word => {
                    return {
                        id: word.id,
                        text: word.text,
                        start: word.start,
                        end: word.end
                    }
                })
            }

            TranscriptionTextRepository.editTranscriptionText(textItem.id, request)
                .then(response => {
                    selectedTextItem = response.data
                    toast.success("Успешно е направена промената.");
                    onClose();
                })
                .catch(error => toast.error(error.message))
        }
    }

    return <Modal
        open={selectedTextItem && textItem}
        // onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        {
            selectedTextItem && textItem &&
            <Box sx={style}>
                <div className={"mb-3 pb-1 border-bottom"}>
                    <h5>Текст</h5>
                </div>
                <div className={"pb-3"}>
                    <TranscriptionFileTextGridText onClick={(word) => setSelectedWord(word)}
                                                   onDoubleClick={() => {
                                                   }}
                                                   position={-1}
                                                   texts={textItem ? [textItem] : []}
                                                   search={null}/>
                </div>
                <div className={"pb-3"}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Тип на говор</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={textItem?.label}
                            label="Тип на говор"
                            onChange={(event) => {
                                setTextItem(prevState => {
                                    return {
                                        ...prevState,
                                        label: event.target.value,
                                        score: 1,
                                    }
                                })
                            }}
                        >
                            <MenuItem value={"Neutral"}>Neutral</MenuItem>
                            <MenuItem value={"Hate"}>Hate speech</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {
                    selectedWord &&
                    <>
                        <div className={"pb-3"}>
                            <TextField
                                fullWidth
                                label={"Збор"}
                                onChange={onWordChange}
                                value={selectedWord?.text}/>
                        </div>
                        <div className={"pb-3"}>
                            Започнува: {convertHMS(selectedWord?.start / 1000)}{selectedWord ?
                            <small>{"." + selectedWord.start % 1000}</small> : null}
                        </div>
                        <div className={"pb-3"}>
                            Завршува: {convertHMS(selectedWord?.end / 1000)}{selectedWord ?
                            <small>{"." + selectedWord.end % 1000}</small> : null}
                        </div>
                    </>
                }
                <div>
                    <button type={"button"} onClick={onSave} className={"btn btn-outline-success rounded-0 me-3"}>
                        Зачувај
                    </button>
                    <button type={"button"} onClick={onClose}
                            className={"btn btn-outline-light text-dark rounded-0 border"}>
                        Затвори
                    </button>
                </div>

            </Box>
        }
    </Modal>
}