import React, {useEffect, useRef, useState} from "react";
import {IconButton} from "@material-ui/core";
import {FaPause, FaPlay, FaRedo, FaUndo, FaVolumeMute, FaVolumeUp} from "react-icons/fa";
import Peaks from "peaks.js";
import {convertHMS} from "../utils/ConvertToHMS";
import {TbTextWrapDisabled} from "react-icons/tb";

export const WaveformViewFuncComponent = ({
                                              audioUrl,
                                              waveformDataUrl,
                                              audioContext,
                                              audioContentType,
                                              followText,
                                              setFollowText,
                                              position,
                                              setPosition,
                                              wordPosition,
                                              transcription
                                          }) => {
    const overviewWaveformRef = useRef(null);
    const audioElementRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [peaks, setPeaks] = useState();
    const [currentTime, setCurrentTime] = useState();
    const [muted, setMuted] = useState(false);

    useEffect(() => {
        if (audioUrl)
            initPeaks();
    }, [audioUrl])

    useEffect(() => {
        if (wordPosition && audioElementRef.current) {
            setIsPlaying(false);
            audioElementRef.current.pause()
            seekTo(wordPosition)

            setIsPlaying(true);
            audioElementRef.current.play()
        }
    }, [wordPosition])

    useEffect(() => {
        if (audioElementRef.current) {
            setCurrentTime(audioElementRef.current?.currentTime)
        }
    }, [audioElementRef.current?.currentTime])

    const initPeaks = () => {
        const options = {
            containers: {
                overview: overviewWaveformRef.current,
            },
            mediaElement: audioElementRef.current,
            keyboard: true,
            logger: console.error.bind(console),
            waveformColor: "#666",

        };

        if (waveformDataUrl) {
            options.dataUri = {
                json: waveformDataUrl
            };
        } else if (audioContext) {
            options.webAudio = {
                audioContext: audioContext
            };
        }

        audioElementRef.current.src = audioUrl;

        if (peaks) {
            peaks.destroy();
            setPeaks(null);
        }

        Peaks.init(options, (err, peaks) => {
            setPeaks(peaks);
            onPeaksReady();
        });
    }

    const onPeaksReady = () => {
        // Do something when the Peaks instance is ready for use
        console.log("Peaks.js is ready");
    }

    const seekTo = (position) => {
        if (!audioElementRef.current) {
            return;
        }
        audioElementRef.current.currentTime = position;
    }

    const skipBack = () => {
        seekTo(currentTime - 15)
    }

    const skipAhead = () => {
        seekTo(currentTime + 15)
    }

    const onMuteHandle = () => {
        if (audioElementRef.current == null) {
            return;
        }
        const currentState = audioElementRef.current.muted
        audioElementRef.current.muted = !currentState
        setMuted(!currentState);
    }

    const onPositionChange = (timeNow) => {
        if (timeNow !== position) {
            setPosition(timeNow);
            setCurrentTime(timeNow);
            if (followText) {
                const item = transcription.transcriptionText.find(item => {
                    let start = item.words[0]?.start;
                    let end = item.words[item.words.length - 1]?.end;
                    start = start ? start / 1000 : null;
                    end = end ? end / 1000 : null;

                    return position > start && position <= end;
                });
                if (item) {
                    const getMeTo = document.getElementById(`text-${item.id}`);
                    getMeTo.scrollIntoView(true);
                }
            }
        }
    };

    const initAudioButtons = () => {
        return <div className={"col-12 my-0 text-dark d-inline-flex"}>
            <div className={"ps-2 my-auto"}>
                <IconButton aria-label="volume-off" size="medium" color={"inherit"}
                            title={audioElementRef.current && !audioElementRef.current.paused ? "Pause" : "Play"}
                            onClick={onPlayPauseHandle}>
                    {
                        isPlaying ?
                            <FaPause size={20}/> :
                            <FaPlay size={20}/>
                    }
                </IconButton>
            </div>
            <div className={"ps-2 my-auto"}>
                <IconButton aria-label="volume-off" size="medium" color={"inherit"}
                            title={"Одете назад 15 секунди"}
                            className={"me-1"}
                            onClick={skipBack}>
                    <FaUndo size={15} className={"text-dark m-1 pointer"}/>
                </IconButton>

                <span
                    className={"my-auto"}>{convertHMS(currentTime)} - {convertHMS(audioElementRef.current?.duration)}</span>
                <IconButton aria-label="volume-off" size="medium" color={"inherit"}
                            title={"Одете напред 15 секунди"}
                            className={"ms-1"}
                            onClick={skipAhead}>
                    <FaRedo size={15} className={"text-dark m-1 pointer"}/>
                </IconButton>

            </div>
            <div className={" my-auto"}>
                {
                    !muted ?
                        <IconButton aria-label="volume-off" size="medium" color={"inherit"}
                                    title={"Исклучи глас"}
                                    onClick={onMuteHandle}>
                            <FaVolumeUp size={25}/>
                        </IconButton>
                        :
                        <IconButton aria-label="start" size="medium"
                                    className={"text-secondary-light"}
                                    title={"Вклучи глас"}
                                    onClick={onMuteHandle}>
                            <FaVolumeMute size={25}/>
                        </IconButton>
                }
            </div>
            <div className={"my-auto"}>
                <IconButton aria-label="start" size="medium"
                            title={followText ? "Исклучи пратење на текстот" : "Вклучи пратење на текстот"}
                            className={!followText ? "text-secondary-light" : "text-dark"}
                            onClick={() => setFollowText(!followText)}>
                    <TbTextWrapDisabled size={25}/>
                </IconButton>
            </div>
        </div>
    }

    const onPlayPauseHandle = () => {
        if (audioElementRef.current == null) {
            return;
        }
        if (audioElementRef.current?.paused) {
            audioElementRef.current.play();
            setIsPlaying(true);
            return;
        }
        audioElementRef.current.pause();
        setIsPlaying(false);
    }

    return (
        <>
            {initAudioButtons()}
            <audio ref={audioElementRef}
                   src={audioUrl || ""}
                   controls={false}
                   onEnded={() => setIsPlaying(false)}
                   onTimeUpdate={(event) => onPositionChange(event.target.currentTime)}>
                Your browser does not support the audio element.
            </audio>
            <div className="overview-container mx-auto" style={{width: "98%", height: "100px"}} ref={overviewWaveformRef}/>
        </>

    )
}