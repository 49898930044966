import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/slider.css';
import "@popperjs/core/dist/cjs/popper"
import "bootstrap/dist/css/bootstrap.css"
import 'bootstrap/dist/js/bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {AuthContextProvider} from "./context/auth-context";
import {FileTranscriptionContextProvider} from "./context/create-file-transcription-context";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthContextProvider>
        <FileTranscriptionContextProvider>
            <App/>
        </FileTranscriptionContextProvider>
    </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
