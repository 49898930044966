import React from "react";
import {
    Route,
    Redirect
} from 'react-router-dom';

export const ProtectedRoute = ({ isAuthenticated, children, redirectPath, ...rest }) => {
    console.log(isAuthenticated, rest, redirectPath, children)
    return (
        <Route
            {...rest}
            render={
                ({ location }) => (
                    isAuthenticated
                        ? (
                            children
                        ) : (
                            <Redirect
                                to={{
                                    pathname: redirectPath || '/',
                                    state: { from: location }
                                }}
                            />
                        ))
            }
        />
    );
};