import React, {useContext, useState} from "react";
import {Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography} from "@material-ui/core";
import {DropzoneAreaBase} from "material-ui-dropzone";
import {AttachFile} from "@material-ui/icons";
import {TranscriptionRepository} from "../../repository/TranscriptionRepository";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import AudioRecorder from "../../utils/AudioRecorder";
import {FileTranscriptionContext} from "../../context/create-file-transcription-context";
import LinearProgressWithLabel from "../../UI/LinearProgressBar";
import moment from "moment";
import {CategoryType} from "../../consts/CategoryType";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 500,
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1
};

export const UploadFileType = {
    VOICE_RECORD: "VOICE_RECORD",
    FILE: "FILE"
}

export const CreateTranscriptionFileModal = ({onClose, isOpen, type}) => {
    const [formData, setFormData] = useState({
        name: {
            value: "",
            error: false,
            helperText: "Полето е задолжително"
        },
        category: {
            value: "",
            error: false,
            helperText: "Полето е задолжително"
        },
        source: {
            value: "",
            error: false,
            helperText: "Полето е задолжително"
        },
        audio: {
            value: null,
            error: false,
            helperText: "Полето е задолжително"
        }
    })

    const history = useHistory();
    const fileTranscriptionContext = useContext(FileTranscriptionContext)

    const checkIsValid = () => {
        let isValid = true;
        const newState = formData;
        if (newState.name.value.trim() === "") {
            newState.name.error = true;
            isValid = false;
        } else {
            newState.name.error = false;
        }
        if (type === UploadFileType.FILE) {
            if (newState.source.value.trim() === "") {
                newState.source.error = true;
                isValid = false;
            } else {
                newState.source.error = false;
            }
            if (newState.category.value.trim() === "") {
                newState.category.error = true;
                isValid = false;
            } else {
                newState.source.error = false;
            }
        }
        if (newState.audio.value === null) {
            newState.audio.error = true;
            isValid = false;
        } else {
            newState.audio.error = false;
        }
        setFormData(newState);
        return isValid;
    }

    const onSubmitHandle = (event) => {
        event.preventDefault();
        if (checkIsValid()) {
            const dto = {
                name: formData.name.value,
                source: formData.source.value,
                category: type === UploadFileType.FILE ? formData.category.value : CategoryType.VOICE_RECORDING,
            }
            TranscriptionRepository.preCreateTranscriptionFile(dto)
                .then(response => {
                    fileTranscriptionContext.setFileTranscription(response.data);
                    fileTranscriptionContext.uploadFile(response.data.id, formData.name.value, formData.audio.value, onClose);
                })
                .catch(err => {
                    console.log(err);
                    toast.error(err.message);
                })
        }
    }

    return <Modal
        open={isOpen}
        // onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            {
                !fileTranscriptionContext.isUploading ? <div className={"text-end py-0 my-0"}>
                    <button type={"button"} onClick={onClose} className={"btn btn-link btn-close py-0 my-0"}/>
                </div> : null
            }
            {
                fileTranscriptionContext.isUploading ?
                    <div className={"p-3"}>
                        <h3>{fileTranscriptionContext?.fileTranscription?.name}</h3>
                        <p>{moment(fileTranscriptionContext?.fileTranscription?.dateCreated).format("DD.MM.YYYY HH:mm")}</p>
                        <LinearProgressWithLabel value={fileTranscriptionContext.percent}/>
                        <small className={"text-secondary"}>Ве молиме почекајте да се прикачи документот</small>
                    </div> :
                    <div className={"p-3 pt-0"}>
                        <Typography id="modal-modal-title" className={"pb-3"} variant="h6" component="h3">
                            Креирање на нов запис
                        </Typography>

                        <div>
                            <TextField
                                id="standard-basic"
                                fullWidth
                                onChange={event => {
                                    setFormData(prevState => {
                                        return {
                                            ...prevState,
                                            name: {
                                                ...prevState.name,
                                                value: event.target.value
                                            }
                                        }
                                    })
                                }}
                                required={true}
                                value={formData.name.value}
                                error={formData.name.error}
                                helperText={formData.name.error ? formData.name.helperText : ""}
                                label="Внесете име на документот"
                                variant="standard"/>
                        </div>

                        {
                            type === UploadFileType.FILE ?
                                <>
                                    <div className={"pt-3"}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="demo-simple-select-standard-label">Категорија</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={formData.category.value}
                                                error={formData.category.error}
                                                required={true}
                                                helperText={formData.category.error ? formData.category.helperText : ""}
                                                onChange={event => {
                                                    setFormData(prevState => {
                                                        return {
                                                            ...prevState,
                                                            category: {
                                                                ...prevState.category,
                                                                value: event.target.value
                                                            }
                                                        }
                                                    })
                                                }}
                                                label="Категорија"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={CategoryType.RADIO}>Радио</MenuItem>
                                                <MenuItem value={CategoryType.TV}>Телевизија</MenuItem>
                                                <MenuItem value={CategoryType.OTHER}>Друго</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className={"pt-3"}>
                                        <TextField
                                            id="standard-basic"
                                            fullWidth
                                            onChange={event => {
                                                setFormData(prevState => {
                                                    return {
                                                        ...prevState,
                                                        source: {
                                                            ...prevState.source,
                                                            value: event.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                            required={true}
                                            value={formData.source.value}
                                            error={formData.source.error}
                                            helperText={formData.source.error ? formData.source.helperText : ""}
                                            label="Извор"
                                            variant="standard"/>
                                    </div>
                                    <div className={"pt-3"}>
                                        <DropzoneAreaBase
                                            Icon={AttachFile}
                                            showAlerts={false}
                                            acceptedFiles={['audio/*']}
                                            dropzoneText={formData.audio.value?.name || "Ве молиме прикачете го аудио документот"}
                                            dropzoneClass={formData.audio.error ? "border-danger bg-danger bg-opacity-25" : ""}
                                            onAdd={(files) => {
                                                setFormData(prevState => {
                                                    return {
                                                        ...prevState,
                                                        audio: {
                                                            ...prevState.audio,
                                                            value: files[0].file
                                                        }
                                                    }
                                                })
                                            }}
                                            maxFileSize={600000000}
                                        />
                                    </div>
                                </>
                                :
                                <div className={"pt-3"}>
                                    <AudioRecorder handleAudioRecord={(data) => {
                                        setFormData(prevState => {
                                            const myFile = new File([new Blob(data)], 'audio.wav', {
                                                type: "audio/wav",
                                            });
                                            return {
                                                ...prevState,
                                                audio: {
                                                    ...prevState.audio,
                                                    value: myFile
                                                }
                                            }
                                        })
                                    }
                                    }/>
                                </div>
                        }


                        <div className={"py-3"}>
                            <button
                                className={"btn btn-success rounded-0"}
                                disabled={!formData.name.value || !formData.audio.value}
                                onClick={onSubmitHandle}
                                type={"button"}>
                                Креирај
                            </button>
                        </div>
                    </div>
            }
        </Box>
    </Modal>
}