import axios from "../axios/axios";

export const UserRepository = {
    login: (username, password) => {
        const auth = {username:username,password:password}
        return axios.post("/login",auth);
    },
    identifyUser: () => {
        return axios.post(`/api/user/me`);
    },
    getAllRoles: () => {
        return axios.get('/api/role');
    },
    register: (user) => {
        return axios.post(`/api/user`, user)
    },
    getAllUsers: () => {
        return axios.get(`/api/user/all`)
    },
    forgotPassword: (email) => {
        return axios.post(`/api/user/reset-password-token?email=${email}`)
    },
    resetPassword: (token, password) => {
        return axios.post(`/api/user/reset-password?token=${token}`, {}, {
            headers: {
                password: password
            }
        })
    }
}