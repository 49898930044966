import React, {useEffect} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {useContext} from "react";
import {AuthContext} from "../context/auth-context";
import LandingPage from "./auth/LandingPage";
import {UserResetPassword} from "./auth/UserResetPassword";
import {ForgotPassword} from "./auth/ForgotPassword";
import {LoadingPage} from "../UI/LoadingPage";
import {TranscriptionFiles} from "./transcriptionFiles/TranscriptionFiles";
import {ProtectedRoute} from "../utils/ProtectedRoute";
import {TranscriptionFile} from "./transcriptionFiles/details/TranscriptionFile";
import {AUTH_TOKEN} from "../axios/axios";


function App() {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        authContext.checkIsLoggedIn();
    }, []);

    return (
        <React.Suspense fallback={<LoadingPage/>}>
            <div className={"bg-transparent"} style={{width: "100vw", height: "100vh"}}>
                <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    className={"me-3 mb-3"}/>

                <Router>
                    <Switch>
                        <ProtectedRoute
                            path="/"
                            exact
                            isAuthenticated={!!localStorage.getItem(AUTH_TOKEN)}
                            redirectPath={!!localStorage.getItem(AUTH_TOKEN)? "/" : "/login"}
                        >
                            <TranscriptionFiles/>
                        </ProtectedRoute>
                        <ProtectedRoute
                            path="/transcription/:id"
                            exact
                            isAuthenticated={authContext.isAuth}
                            redirectPath={!!localStorage.getItem(AUTH_TOKEN)? "/" : "/login"}
                        >
                            <TranscriptionFile/>
                        </ProtectedRoute>
                        <ProtectedRoute
                            path="/login"
                            exact
                            isAuthenticated={!localStorage.getItem(AUTH_TOKEN)}
                        >
                            <LandingPage/>
                        </ProtectedRoute>
                        <ProtectedRoute
                            path="/user/reset-password"
                            exact
                            isAuthenticated={!localStorage.getItem(AUTH_TOKEN)}
                        >
                            <UserResetPassword/>
                        </ProtectedRoute>
                        <ProtectedRoute
                            path="/forgot-password"
                            exact
                            isAuthenticated={!localStorage.getItem(AUTH_TOKEN)}
                        >
                            <ForgotPassword/>
                        </ProtectedRoute>
                    </Switch>
                </Router>
            </div>
        </React.Suspense>
    );
}

export default App;
