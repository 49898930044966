import React, {useState} from "react";
import {TranscriptionRepository} from "../repository/TranscriptionRepository";
import {toast} from "react-toastify";

export const FileTranscriptionContext = React.createContext({
    isUploading: false,
    percent: 0,
    fileTranscription: null,
    setFileTranscription: (fileTranscription) => {},
    uploadFile: (id, name, audio, onClose) => {}
})

export const FileTranscriptionContextProvider = props => {
    const [isUploading, setIsUploading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [fileTranscription, setFileTranscription] = useState(null);

    const uploadFileHandle = (id, name, audio, onClose) => {
        setIsUploading(true);
        const formDataRequest = new FormData();
        formDataRequest.append("name", name);
        formDataRequest.append("audio", audio);

        const options = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setPercent(percent);
                console.log(percent)
            }
        }

        TranscriptionRepository.createTranscriptionFile(id, formDataRequest, options)
            .then((response) => {
                toast.success("Документот е успешно прикачен");
            })
            .catch((err) => {
                toast.error(err.message);
            })
            .finally(() => {
                setFileTranscription(null);
                setPercent(0);
                setIsUploading(false);
                onClose();
            });
    }

    const handleFileTranscription = (ft) => {
        setFileTranscription(ft);
    }

    return (
        <FileTranscriptionContext.Provider value={{
            isUploading: isUploading,
            percent: percent,
            fileTranscription: fileTranscription,
            setFileTranscription: handleFileTranscription,
            uploadFile: uploadFileHandle
        }}>
            {props.children}
        </FileTranscriptionContext.Provider>
    )
}