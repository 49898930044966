import {Recorder} from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'
import React, {useEffect, useState} from "react";
import OpusMediaRecorderView from "./OpusMediaRecorderView";
import {FaMicrophone, FaStop} from "react-icons/fa";
import {convertHMS} from "./ConvertToHMS";
import {IconButton} from "@material-ui/core";


const AudioRecorder = ({handleAudioRecord}) => {
    const [audioDetails, setAudioDetails] = useState({
        data: [],
        blob: new Blob([])
    })
    const [isRecording, setIsRecording] = useState(false);
    const [timeRecorded, setTimeRecorded] = useState(0);
    useEffect(() => {
        let interval = null;
        if (isRecording) {
            interval = setInterval(() => {
                setTimeRecorded(seconds => seconds + 1);
            }, 1000);
        } else if (!isRecording && timeRecorded !== 0) {
            setTimeRecorded(0);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRecording])

    return (
        <OpusMediaRecorderView
            onDataAvailable={(e) => {
                const data = [...audioDetails.data, e.data];
                setAudioDetails({
                    data: data,
                    blob: new Blob(data)
                })
                handleAudioRecord(data)
            }}
            render={({ state, start, stop, pause, resume }) => (
                <div className={"text-center"}>
                    {/*<p>{state}</p>*/}
                    <p>{convertHMS(timeRecorded)}</p>
                    {
                        isRecording ?
                            <IconButton aria-label="stop" size="large" onClick={() => {
                                setIsRecording(false);
                                stop();
                            }} >
                                <FaStop size={30} fontSize="large" />
                            </IconButton>
                            :
                            <IconButton aria-label="start" size="large" color={"secondary"} onClick={() => {
                                if (audioDetails.data.length > 0) {
                                    setAudioDetails({
                                        data: [],
                                        blob: new Blob([])
                                    })
                                }
                                setIsRecording(true);
                                start();
                            }} >
                                <FaMicrophone size={30} fontSize="large" />
                            </IconButton>
                    }
                    {
                        audioDetails.data.length ?
                        <div>
                            <audio
                                className={"mx-auto my-2"}
                                src={audioDetails.data.length ? URL.createObjectURL(audioDetails.blob) : ''}
                                controls
                            />
                        </div>: null
                    }
                </div>
            )}
        />
    );
}

export default AudioRecorder;