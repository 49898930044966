import React, {useState} from 'react';
import {UserRepository} from "../../repository/UserRepository";
import {toast} from "react-toastify";
import {useLocation, useHistory, Link} from "react-router-dom";

export const UserResetPassword = () => {
    const [password, setPassword] = useState({
        password: "",
        confirmPassword: ""
    })

    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');

    const resetPassword = (event) => {
        event.preventDefault();
        if (!password.password) {
            toast.error("Пасворд полето е задолжително.");
            return;
        }
        if (!password.confirmPassword) {
            toast.error("Потврда на пасворд е задолжително.");
            return;
        }
        if (password.password !== password.confirmPassword) {
            toast.error("Лозинките не се совпаѓаат.");
            return;
        }

        UserRepository.resetPassword(token, password.password)
            .then(response => {
                toast.success("Успешно е променета лозинката.")
                window.open(window.location.origin,'_blank');
                window.setTimeout(function(){this.close();},1000);
            })
            .catch(error => {
                toast.error("Не успешно е променета лозинката.")
            })
    }

    return (
        <div className={"landing-page h-100"} style={{background: "#323445"}}>
            <div className="container pt-5 h-100">
                <div className="row display-flex h-100">
                    <div className="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 my-auto">
                        <div className="landing-content text-white ">
                            <h2>Транскрипција.мк ви овозможува брза и едноставна онлајн конверзија на вашите говорни
                                аудио документи во текст.</h2>
                            <h6>Транскрипцијата ја добивате во текстуален документ.</h6>
                        </div>
                    </div>
                    <div
                        className="col col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto">
                        <div className="registration-login-form bg-white rounded text-secondary p-3">
                            <h5 className="pb-3 px-0 border-bottom">Ресетирање на лозинка</h5>
                            <form className="content" onSubmit={resetPassword}>
                                <div className="row">
                                    <div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-floating mb-3">
                                            <input type="password"
                                                   className="form-control"
                                                   id="password"
                                                   value={password.password}
                                                   onChange={event => setPassword(prevState => {
                                                       return {
                                                           ...prevState,
                                                           password: event.target.value
                                                       }
                                                   })}
                                                   placeholder="Нова лозинка" />
                                            <label htmlFor="password">Нова лозинка</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input className="form-control"
                                                   id={"confirmPassword"}
                                                   value={password.confirmPassword}
                                                   onChange={event => setPassword(prevState => {
                                                       return {
                                                           ...prevState,
                                                           confirmPassword: event.target.value
                                                       }
                                                   })}
                                                   placeholder="Потврди лозинка"
                                                   type="password"/>
                                            <label htmlFor="confirmPassword">Нова лозинка</label>
                                        </div>
                                        <button type={"submit"}
                                                className="btn mb-3 btn-lg btn-primary w-100">Ресетирај
                                        </button>
                                        <div className="text-start mb-3">
                                            <Link to={"/login"} className="forgot text-start py-2 text-secondary">
                                                Најави се
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
