import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {AiOutlineClose} from "react-icons/ai";
import {StatusTranslator} from "../../../consts/StatusTranslator";
import {FaAngleDown, FaAngleUp, FaTrashAlt} from "react-icons/fa";

export const TranscriptionFileHeader = ({transcription, onDeleteHandle}) => {
    const [totalHateSpeech, setTotalHateSpeech] = useState(0);
    const [positionHateSpeech, setPositionHateSpeech] = useState(0);


    const scrollToHateSpeechText = pos => {
        const items = transcription.transcriptionText.filter(item => item.label === "Hate");
        let item = items[pos];
        if (item) {
            const getMeTo = document.getElementById(`text-${item.id}`);
            getMeTo.scrollIntoView(true);
            getMeTo.classList.add("fw-bold");
        }
        // remove class when increment
        item = items[pos - 1];
        if (item) {
            let removeClass = document.getElementById(`text-${item.id}`)
            removeClass.classList.remove("fw-bold");
        }
        // remove class when decrement
        item = items[pos + 1];
        if (item) {
            let removeClass = document.getElementById(`text-${item.id}`)
            removeClass.classList.remove("fw-bold");
        }
    }

    const incrementHateSpeech = () => {
        if (totalHateSpeech > positionHateSpeech) {
            const pos = positionHateSpeech + 1;
            setPositionHateSpeech(pos);
            scrollToHateSpeechText(pos - 1);
        }
    }

    const decrementHateSpeech = () => {
        if (positionHateSpeech > 0) {
            const pos = positionHateSpeech - 1;
            setPositionHateSpeech(pos);
            scrollToHateSpeechText(pos - 1);
        }
    }

    useEffect(() => {
        if (transcription) {
            let counter = 0;
            transcription.transcriptionText.forEach(item => {
                if (item.label === "Hate") {
                    counter++;
                }
            })
            setTotalHateSpeech(counter);
        }
    }, [transcription])

    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-light bg-light border-bottom py-2 bg-transparent" aria-label="Third navbar example">
                <div className="container-fluid">
                    <button className="navbar-toggler text-secondary-dimgray" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarsExample03">
                        <div className={"d-inline-flex w-100"}>
                            <div className={" my-auto"}>
                                <Link to={"/"}
                                      className={"btn btn-link my-auto bg-transparent text-secondary-dimgray text-dark border-0 text-decoration-none p-0"}>
                                    <AiOutlineClose size={30}/>
                                </Link>
                            </div>
                            <div  className={"text-start px-3"}>
                                <h5 className={"mb-0"}>{transcription?.name}</h5>
                                <small className={"mb-0 small"}>{StatusTranslator[transcription?.status]}</small>
                            </div>
                            <div className={"ms-auto"}>
                                <div className={"d-inline-flex w-100 "}>
                                    <div className={"my-0 ms-auto me-2 px-3 text-secondary-dimgray"} >
                                        <p
                                            className={"p-0 m-0 "} style={{fontSize: "0.7em"}}>Вкупно пронајдени содржини со говор на
                                            омраза: {totalHateSpeech}</p>
                                        <p className={"p-0 m-0 text-end "} style={{fontSize: "0.7em"}}>
                                            {positionHateSpeech} од {totalHateSpeech}
                                            <button className={"btn rounded-circle border-0 p-0 m-1 my-0"}
                                                    disabled={totalHateSpeech === positionHateSpeech} onClick={incrementHateSpeech}>
                                                <FaAngleDown className={""}/>
                                            </button>
                                            <button className={"btn rounded-circle border-0 p-0 m-1 my-0"}
                                                    disabled={0 === positionHateSpeech} onClick={decrementHateSpeech}>
                                                <FaAngleUp className={""}/>
                                            </button>
                                        </p>

                                    </div>
                                   <div className={"mb-0"}>
                                       <button type={"button"} onClick={() => onDeleteHandle(transcription)}
                                               className={"btn btn-outline-danger btn-sm ms-auto rounded-0 mb-0"}>
                                           <FaTrashAlt/> Избриши
                                       </button>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}