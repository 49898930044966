import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {TranscriptionRepository} from "../../../repository/TranscriptionRepository";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../../utils/ConfirmDialogOptions";
import {toast} from "react-toastify";
import {EditTextItem} from "../../../UI/EditTextItem";
import {Chat} from "../../../utils/Chat";
import {EditSpeaker} from "../../../UI/EditSpeaker";
import {TranscriptionFileFooter} from "./TranscriptionFileFooter";
import {TranscriptionFileHeader} from "./TranscriptionFileHeader";

export const TranscriptionFile = props => {
    const params = useParams();
    const [transcription, setTranscription] = useState(null);

    const [peaks, setPeaks] = useState([]);
    const [textItemSelected, setTextItemSelected] = useState(null);
    const [speakerSelected, setSpeakerSelected] = useState(null);
    const [followText, setFollowText] = useState(true);
    const history = useHistory();
    const [position, setPosition] = useState();
    const [wordPosition, setWordPosition] = useState(null);

    const searchURL = useLocation().search;
    const searchData = new URLSearchParams(searchURL).get('search');

    useEffect(() => {
        if (params.id) {
            fetchData();
        }
    }, [params.id]);

    const fetchData = async () => {
        const transcriptionResponse = await TranscriptionRepository.getTranscription(params.id)
            .then(response => response.data)
            .catch(error => console.log(error));
        setTranscription(transcriptionResponse);
        if (transcriptionResponse) {
            const peaksResponse = await TranscriptionRepository.getPeaks(params.id)
                .then(response => response.data.peaks)
                .catch(error => console.log(error.message));
            setPeaks(peaksResponse)
        }
    }

    const onWordClickHandle = word => {
        if (word) {
            setWordPosition(word.start / 1000)
        }
    }


    const onDeleteHandle = async (transcriptionFile) => {
        const response = await confirm(`Дали сте сигурни дека сакате да ја избришете документот '${transcriptionFile?.name || transcriptionFile?.audioFile?.name}'`, confirmDialogOptions)
        if (response) {
            TranscriptionRepository.deleteTranscription(transcriptionFile.id)
                .then(response => {
                    history.replace("/");
                    toast.success("Успешно е избришан документот");
                })
                .catch(error => toast.error(error.message))
        }
    }

    const onTextItemDoubleClick = (text) => {
        setTextItemSelected(text);
    }

    const onSpeakerDoubleClick = (text) => {
        setSpeakerSelected(text);
    }

    return <>
        {
            textItemSelected && <EditTextItem selectedTextItem={textItemSelected} onClose={() => {
                setTextItemSelected(null);
                fetchData();
            }}/>
        }
        {
            speakerSelected && <EditSpeaker selectedTextItem={speakerSelected} onClose={() => {
                setSpeakerSelected(null);
                fetchData();
            }}/>
        }
        <div className={"flex-direction-column layout-main bg-transparent"} style={{height: "100vh"}}>
            <TranscriptionFileHeader transcription={transcription} onDeleteHandle={onDeleteHandle} />

            <div className={"bg-white p-3 shadow overflow-y transcription-details"}>
                <div className={"overflow-y text-grid"}>
                    {/*<TranscriptionFileTextGridText onClick={onWordClickHandle}*/}
                    {/*                               onDoubleClick={onDoubleClickHandle}*/}
                    {/*                               position={position}*/}
                    {/*                               texts={transcription?.transcriptionText}*/}
                    {/*                               search={searchData}/>*/}
                    <Chat
                        onClick={onWordClickHandle}
                        onTextItemDoubleClick={onTextItemDoubleClick}
                        onSpeakerDoubleClick={onSpeakerDoubleClick}
                        textItems={transcription?.transcriptionText}
                        search={searchData}
                        position={position}/>
                </div>
            </div>

            <TranscriptionFileFooter
                position={position}
                setPosition={setPosition}
                transcription={transcription}
                duration={transcription?.duration}
                followText={followText}
                setFollowText={setFollowText}
                peaks={peaks}
                wordPosition={wordPosition}
                // seekTo={seekTo}
            />
        </div>
    </>
}