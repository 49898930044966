import React, {useContext} from "react";
import {NavigationBar} from "../components/navigation/NavigationBar";
import {AuthContext} from "../context/auth-context";

export const LayoutMainHeader = ({children, backButton}) => {
    const authContext = useContext(AuthContext)
    return <>
        {authContext.isAuth && <NavigationBar/>}

        <div className={"flex-direction-column layout-main bg-white"}>
            {children}
        </div>
    </>
}