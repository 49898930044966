import React, {useEffect, useState} from "react";
import {TranscriptionRepository} from "../../repository/TranscriptionRepository";
import {paginationData} from "../../consts/PaginationData";
import moment from "moment";
import {StatusTranslator} from "../../consts/StatusTranslator";
import {Link, useLocation} from "react-router-dom";
import {PaginationUI} from "../../UI/Pagination";
import {LayoutMainHeader} from "../../UI/LayoutMainHeader";
import {confirmDialogOptions} from "../../utils/ConfirmDialogOptions";
import {confirm} from "react-confirm-box";
import {toast} from "react-toastify";
import {CreateTranscriptionFileModal, UploadFileType} from "./CreateTranscriptionFile";
import {StatusTypes} from "../../consts/StatusTypes";
import {HiDocumentText, HiMicrophone} from "react-icons/hi";
import {CategoryType, CategoryTypeTranslate} from "../../consts/CategoryType";
import {AiOutlineUnorderedList} from "react-icons/ai";
import {BiMicrophone, BiRadio, BiTv} from "react-icons/bi";
import {BsFunnel} from "react-icons/bs";

export const TranscriptionFiles = (props) => {
    const [transcriptionFiles, setTranscriptionFiles] = useState([]);
    const [pagination, setPagination] = useState(paginationData);
    const [createTranscriptionFile, setCreateTranscriptionFile] = useState(null);
    const [category,setCategory] = useState(null);

    const search = useLocation().search;
    const searchData = new URLSearchParams(search).get('search');

    useEffect(() => {
        setPagination(paginationData)
    }, [searchData]);

    useEffect(() => {
        fetchData(pagination.page, pagination.size, searchData);

        const interval = setInterval(() => {
            fetchData(pagination.page, pagination.size, searchData);
        }, 1000 * 15);

        return () => clearInterval(interval);
    }, [pagination.page, pagination.size, searchData, category])

    const fetchData = (page, size, search) => {
        TranscriptionRepository.getAllPaged(page, size, search, category)
            .then(response => {
                setTranscriptionFiles(response.data.content)
                setPagination(prevState => {
                    return {
                        ...prevState,
                        totalPages: response.data.totalPages,
                        totalElements: response.data.totalElements
                    }
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    const onDeleteHandle = async (transcriptionFile) => {
        const response = await confirm(`Дали сте сигурни дека сакате да ја избришете документот '${transcriptionFile?.name || transcriptionFile?.audioFile?.name}'`, confirmDialogOptions)
        if (response) {
            TranscriptionRepository.deleteTranscription(transcriptionFile.id)
                .then(response => {
                    fetchData(pagination.page, pagination.size, searchData);
                    toast.success("Успешно е избришан документот");
                })
                .catch(error => toast.error(error.message))
        }
    }

    return (
        <>
            {
                createTranscriptionFile &&
                <CreateTranscriptionFileModal isOpen={createTranscriptionFile}
                                              type={createTranscriptionFile}
                                              onClose={() => {
                                                  setCreateTranscriptionFile(false)
                                                  fetchData(pagination.page, pagination.size, searchData);
                                              }}/>
            }
            <LayoutMainHeader>
                <div className={"d-inline-flex h-100"}>
                    <div className={"bg-light border-end p-2 "} style={{width: "350px"}}>
                        <div className={"border-bottom pb-2"}>
                            <div className="btn-group w-100">
                                <button className="btn btn-success w-100 py-2 create-button rounded-0 mb-0"
                                        type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    Креирај нов запис
                                </button>
                                <ul className="dropdown-menu w-100 py-2">
                                    <li>
                                        <button
                                            className={"btn btn-link text-decoration-none text-dark px-2 py-3 text-start w-100 mb-0"}
                                            onClick={() => setCreateTranscriptionFile(UploadFileType.FILE)}><HiDocumentText
                                            size={20}/> Прикачи документ
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className={"btn btn-link text-decoration-none text-dark px-2 py-3 text-start w-100 mb-0"}
                                            onClick={() => setCreateTranscriptionFile(UploadFileType.VOICE_RECORD)}>
                                            <HiMicrophone size={20}/> Снимај аудио
                                        </button>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className={"py-2"}>
                            <ul className="nav nav-pills flex-column mb-auto">
                                <li className="nav-item">
                                    <button className={`nav-link btn-link w-100 text-start ${category === null ? "bg-secondary bg-opacity-25 rounded-0" : ""} text-dark`} onClick={() => setCategory(null)}>
                                        <AiOutlineUnorderedList size={25} className={"me-2 mt-0"}/>
                                        Сите
                                    </button>
                                </li>
                                <li>
                                    <button className={`nav-link btn-link w-100 text-start ${category === CategoryType.VOICE_RECORDING ? "bg-secondary bg-opacity-25 rounded-0" : ""} text-dark`} onClick={() => setCategory(CategoryType.VOICE_RECORDING)}>
                                        <BiMicrophone size={25} className={"me-2 mt-0"}/>
                                        Гласовно снимање
                                    </button>
                                </li>
                                <li>
                                    <button className={`nav-link btn-link w-100 text-start ${category === CategoryType.TV ? "bg-secondary bg-opacity-25 rounded-0" : ""} text-dark`} onClick={() => setCategory(CategoryType.TV)}>
                                        <BiTv size={25} className={"me-2 mt-0"}/>
                                        Телевизија
                                    </button>
                                </li>
                                <li>
                                    <button className={`nav-link btn-link w-100 text-start ${category === CategoryType.RADIO ? "bg-secondary bg-opacity-25 rounded-0" : ""} text-dark`} onClick={() => setCategory(CategoryType.RADIO)}>
                                        <BiRadio size={25} className={"me-2 mt-0"}/>
                                        Радио
                                    </button>
                                </li>
                                <li>
                                    <button className={`nav-link btn-link w-100 text-start ${category === CategoryType.OTHER ? "bg-secondary bg-opacity-25 rounded-0" : ""} text-dark`} onClick={() => setCategory(CategoryType.OTHER)}>
                                        <BsFunnel size={25} className={"me-2 mt-0"}/>
                                        Друго
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className={"bg-white w-100 h-100 d-flex flex-column"}>

                        <div className={"table-responsive px-3 overflow-y"}>
                            <table className={"table table-hover table-transcriptions align-middle mb-0 bg-white"}>
                                <thead>
                                <tr className={""}>
                                    <th className={"p-3"}>Име</th>
                                    <th className={"p-3"}>Категорија</th>
                                    <th className={"p-3"}>Извор</th>
                                    <th className={"p-3"}>Креирано на</th>
                                    <th className={"p-3"}>Статус</th>
                                    <th className={"p-3"}>Процент</th>
                                    <th className={"w-auto"}/>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    transcriptionFiles.length > 0 ?
                                        transcriptionFiles.map(transcriptionFile => {
                                            let progressBarBg = "";
                                            if (transcriptionFile?.percent <= 25) {
                                                progressBarBg = "bg-danger"
                                            } else if (transcriptionFile?.percent <= 50) {
                                                progressBarBg = "bg-warning"
                                            } else if (transcriptionFile?.percent <= 75) {
                                                progressBarBg = "bg-primary"
                                            } else if (transcriptionFile?.percent <= 100) {
                                                progressBarBg = "bg-success"
                                            }
                                            let badge = "";
                                            if (transcriptionFile.status === StatusTypes.TRANSCRIPTION_FINISHED) {
                                                badge = "bg-success text-success"
                                            } else if (transcriptionFile.status === StatusTypes.TRANSCRIPTION_IN_PROGRESS) {
                                                badge = "bg-warning text-warning"
                                            } else if (transcriptionFile.status === StatusTypes.TRANSCRIPTION_FAILED) {
                                                badge = "bg-danger text-danger"
                                            } else if (transcriptionFile.status === StatusTypes.UPLOADING || transcriptionFile.status === StatusTypes.UPLOADED) {
                                                badge = "bg-secondary text-secondary"
                                            }

                                            return <tr key={transcriptionFile.id}
                                                       className={"transcription-row"}>
                                                <td className={"p-3"}>
                                                    <Link
                                                        to={`/transcription/${transcriptionFile?.id}${searchData ? '?search=' + searchData : ''}`}
                                                        title={"Прегледај"}>
                                                        {transcriptionFile?.name}
                                                    </Link>
                                                </td>
                                                <td className={"p-3"}>
                                                    {transcriptionFile?.category && CategoryTypeTranslate[transcriptionFile.category] || "/"}
                                                </td>
                                                <td className={"p-3"}>
                                                    {transcriptionFile?.source || "/"}
                                                </td>
                                                <td className={"p-3"}>{moment(transcriptionFile?.dateCreated).format("DD.MM.YYYY hh:mm")}</td>
                                                <td className={"p-3"}>
                                                <span
                                                    className={`badge ${badge} bg-opacity-25 rounded-pill d-inline`}>{StatusTranslator[transcriptionFile.status]}</span>
                                                </td>
                                                <td className={"p-3"}>
                                                    <div className="progress my-auto">
                                                        <div className={`progress-bar ${progressBarBg}`} role="progressbar"
                                                             style={{width: `${transcriptionFile?.percent}%`}}
                                                             aria-valuenow="25" aria-valuemin="0"
                                                             aria-valuemax="100">{transcriptionFile?.percent}%
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className={"w-auto text-center"}>
                                                    <button type={"button"}
                                                            onClick={() => onDeleteHandle(transcriptionFile)}
                                                            title={"Избриши"}
                                                            className={"btn btn-link text-danger delete-button text-decoration-none mb-0 py-2 px-3 ms-1 rounded-0"}
                                                            style={{zIndex: 1}}>
                                                        ИЗБРИШИ
                                                    </button>
                                                </td>
                                            </tr>
                                        }) :
                                        <tr>
                                            <td colSpan={6} className={"text center p-3"}>Немате креирано записи</td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className={"mt-auto bg-light py-2 border-top"}>
                            <PaginationUI totalPages={pagination.totalPages}
                                          totalElements={pagination.totalElements}
                                          page={pagination.page}
                                          size={pagination.size}
                                          onRowsChange={(size) => {
                                              console.log(pagination, size)
                                              setPagination({
                                                  ...pagination,
                                                  page: 0,
                                                  size: size
                                              })
                                          }}
                                          onChange={(selected) => {
                                              console.log(selected)
                                              setPagination({
                                                  ...pagination,
                                                  page: selected
                                              })
                                          }}/>
                        </div>
                    </div>
                </div>

            </LayoutMainHeader>
        </>
    )
}