import React, {useState} from 'react';
import {AUTH_TOKEN} from '../axios/axios';
import {UserRepository} from "../repository/UserRepository";
import {toast} from "react-toastify";

export const Roles = {
    ADMIN: "admin",
    MANAGER: "manager",
    USER: "user"
}

export const AuthContext = React.createContext({
    isAuth: false,
    user: {},
    login: () => {},
    logout: () => {},
    identifyUser: () => {},
    checkIsLoggedIn: () => {},
    checkUserHasAnyRoles: () => {},
    checkUserHasEveryRoles: () => {},
})

export const AuthContextProvider = props => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [userRoles, setUserRoles] = useState([]);

    const identifyUser = () => {
        UserRepository.identifyUser()
            .then(response => {
                localStorage.setItem("isAuth", true)
                setIsAuthenticated(true);
                localStorage.setItem("user", JSON.stringify(response.data));
                setUser(response.data);
                setUserRoles(response.data.authorityList.map(authorityItem => authorityItem.authority))
            })
            .catch(error => {
                toast.error(error.message)
                logoutHandler();
                window.location.href = "/login";
            })
    }

    const loginHandler = (token) => {
        localStorage.setItem(AUTH_TOKEN, token)
        setIsAuthenticated(true);
        identifyUser();
    }

    const logoutHandler = () => {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem("isAuth");
        localStorage.removeItem("user");
        setIsAuthenticated(false)
        setUser(null);
        setUserRoles([])
    }

    const checkIsLoggedInHandler = () => {
        if (localStorage.getItem("isAuth") &&
            localStorage.getItem(AUTH_TOKEN) &&
            isAuthenticated === false
        ) {
            setIsAuthenticated(true);
            identifyUser()
        }
    }

    const checkUserHasAnyRoles = (roles) => {
        return userRoles.some(role => roles.includes(role));
    }

    const checkUserHasEveryRoles = (roles) => {
        return userRoles.every(role => roles.includes(role));
    }

    return (
        <AuthContext.Provider value={{
            login: loginHandler,
            logout: logoutHandler,
            checkIsLoggedIn: checkIsLoggedInHandler,
            identifyUser: identifyUser,
            isAuth: isAuthenticated,
            user: user,
            checkUserHasAnyRoles: checkUserHasAnyRoles,
            checkUserHasEveryRoles: checkUserHasEveryRoles
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}