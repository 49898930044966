import React, {useEffect, useState} from "react";

export const TranscriptionFileTextGridText = ({onClick, onDoubleClick, position, texts, search}) => {
    const [textGrid, setTextGrid] = useState([]);

    useEffect(() => {
        if (texts) {
            setTextGrid(texts);
        }
    }, [texts])

    const mapToText = (textItem, index) => {
        if (textItem.words?.length > 0) {
            let previousItem = textGrid[index - 1];
            let previousEnd = null;
            if (previousItem) {
                previousEnd = previousItem.words[previousItem.words.length - 1]?.end
                previousEnd = previousEnd ? previousEnd / 1000 : null;
            }

            let start = textItem.words[0]?.start;
            let end = textItem.words[textItem.words.length - 1]?.end;
            start = start ? start / 1000 : null;
            end = end ? end / 1000 : null;

            return <>
                {/*{!previousEnd || previousEnd - end > 2 ? <h6>{convertHMS(start)} - {convertHMS(end)}</h6> : null}*/}
                {/*{!previousEnd || end - previousEnd > 10 ? <>{previousEnd && <br/>}<h6 className={`${previousEnd ? "pt-3" : ""} pb-1 border-bottom`}>{convertHMS(start)}</h6></> : null}*/}
                {
                    {
                        "Neutral": <span
                            id={`text-${textItem.id}`}
                            key={`text-${textItem.id}`}
                            onDoubleClick={() => onDoubleClick(textItem)}>
                            {
                                textItem?.words?.map(word => {
                                    return <span
                                        className={`word ${position > start && position <= end ? "fw-bold" : ""} ${search === word.text ? 'bg-warning bg-opacity-25' : ''}`}
                                        onClick={() => onClick(word)}
                                        title={`${(word.start / 1000)}s-${(word.end / 1000)}s`}>{word.text} </span>

                                })
                            }
                        </span>,
                        "Hate": <span id={`text-${textItem.id}`}
                                      key={`text-${textItem.id}`}
                                      onDoubleClick={() => onDoubleClick(textItem)}
                                      className={"border border-danger p-1 ps-0 me-1"}>
                    <span className={"text-white bg-danger p-1"}>{textItem.score.toFixed(2)}</span>
                            {textItem?.words?.map(word => <span
                                    key={word.id}
                                    className={`word word-insult ${position > start && position <= end ? "fw-bold" : ""} ${search === word.text ? 'bg-warning bg-opacity-25' : ''}`}
                                    onClick={() => onClick(word)}
                                    title={`${(word.start / 1000)}s-${(word.end / 1000)}s`}> {word.text} </span>)}
                    </span>
                    } [textItem.label]
                }
            </>
        }
        return null;
    }

    return <p>
            {
                textGrid && textGrid.length > 0 ? textGrid.map((textItem, index) => mapToText(textItem, index)) : null
            }
        </p>
}