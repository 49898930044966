export const CategoryType = {
    VOICE_RECORDING: "VOICE_RECORDING",
    OTHER: "OTHER",
    RADIO: "RADIO",
    TV: "TV",
}

export const CategoryTypeTranslate = {
    VOICE_RECORDING: "Гласовно снимање",
    OTHER: "Друго",
    RADIO: "Радио",
    TV: "Телевизија",
}
