import React, {useEffect, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField} from "@material-ui/core";
import {TranscriptionFileTextGridText} from "../components/transcriptionFiles/TranscriptionFileTextGridText";
import {convertHMS} from "../utils/ConvertToHMS";
import {TranscriptionTextRepository} from "../repository/TranscriptionTextRepository";
import {toast} from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 500,
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1
};

export const EditSpeaker = ({selectedTextItem, onClose}) => {
    const [textItem, setTextItem] = useState(null);

    useEffect(() => {
        // {...selectedTextItem} copy by reference
        setTextItem(JSON.parse(JSON.stringify(selectedTextItem)))
    }, [selectedTextItem])

    const onSpeakerChange = (e) => {
        if (textItem) {
            const value = e.target.value;
            const speaker = textItem?.speaker || {};
            speaker.name = value;
            setTextItem(prevState => {
                return {
                    ...prevState,
                    speaker: speaker
                }
            })

        }
    }

    const onSave = (e) => {
        e.preventDefault();
        if (textItem) {
            const speaker = textItem.speaker ? textItem.speaker : {id: null, name: null, coreId: null}
            const request = {
                id: textItem.id,
                text: textItem.words.map(word => word.text).reduce((prev, next) => prev + ' ' + next, '').trim(),
                label: textItem.label,
                score: textItem.score,
                speaker: speaker,
                words: textItem.words.map(word => {
                    return {
                        id: word.id,
                        text: word.text,
                        start: word.start,
                        end: word.end
                    }
                })
            }

            TranscriptionTextRepository.editTranscriptionText(textItem.id, request)
                .then(response => {
                    selectedTextItem = response.data
                    toast.success("Успешно е направена промената.");
                    onClose();
                })
                .catch(error => toast.error(error.message))
        }
    }

    return <Modal
        open={selectedTextItem && textItem}
        // onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        {
            selectedTextItem && textItem &&
            <Box sx={style}>
                <div className={"mb-3 pb-1 border-bottom"}>
                    <h5>Говорник</h5>
                </div>
                <div className={"pb-3"}>
                    <TextField
                        fullWidth
                        label={"Име на говорник"}
                        onChange={onSpeakerChange}
                        value={textItem?.speaker?.name}/>
                </div>
                <div>
                    <button type={"button"} onClick={onSave} className={"btn btn-outline-success rounded-0 me-3"}>
                        Зачувај
                    </button>
                    <button type={"button"} onClick={onClose}
                            className={"btn btn-outline-light text-dark rounded-0 border"}>
                        Затвори
                    </button>
                </div>

            </Box>
        }
    </Modal>
}