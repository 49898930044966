import React, {useContext, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {FaSearch} from "react-icons/fa";
import {AuthContext} from "../../context/auth-context";
import {FileTranscriptionContext} from "../../context/create-file-transcription-context";
import {FiLogOut} from "react-icons/fi";

export const NavigationBar = () => {
    const [search, setSearch] = useState("");
    const authContext = useContext(AuthContext);
    const transcriptionFileContext = useContext(FileTranscriptionContext);
    const history = useHistory();

    const searchHandle = (event) => {
        event.preventDefault();
        history.push(`/?search=${search}`)
    }

    const logout = () => {
        authContext.logout();
        history.push("/login")
    }

    return (
        <>
            <nav className="header navbar navbar-expand-sm navbar-light bg-transparent shadow-sm" aria-label="Third navbar example">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">АВМУ</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarsExample03">
                        <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                            <li className="nav-item">
                                <Link to={"/"}
                                      className={"btn btn-link my-auto bg-transparent text-dark border-0 text-decoration-none"}>
                                    Почетна
                                </Link>
                            </li>
                        </ul>
                        <form className={"mx-auto"} onSubmit={searchHandle}>
                            <div className={"input-group"}>
                                <input className="form-control border-end-0 search"
                                       placeholder="Search"
                                       onChange={(event) => setSearch(event.target.value)}
                                       value={search}
                                       aria-label="Search"
                                       type="text"/>
                                <button className={"btn h-100 m-0 bg-white border border-start-0"} type={"submit"}>
                                    <FaSearch/>
                                </button>
                            </div>
                        </form>
                        <div className={"ms-auto d-inline-flex"}>
                            <div className="dropdown">
                                <button className="btn btn-link text-decoration-none mb-0 text-dark" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className={"mx-2 my-auto"}>{authContext?.user?.username}</span>
                                    <div id="user-initials-image" className="adf-userinfo-pic mx-2">
                                        {
                                            authContext?.user?.username?.split(" ").reduce((prevValue, nextValue) => prevValue + nextValue[0], "").substr(0, 2)
                                        }
                                    </div>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <button type={"button"}
                                                className={"btn btn-link text-dark text-decoration-none mb-0 d-inline-flex"}
                                                onClick={logout}>
                                            <FiLogOut size={25}/> <div className={"h-100 ms-2 my-auto"}>Одјави се</div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}