import React from "react";
import {Pagination} from "@material-ui/lab";
import {Stack} from "@mui/material";
import {TablePagination} from "@material-ui/core";

export const PaginationUI = (props) => <Stack spacing={2}
                                              direction="row"
                                              justifyContent="end"
                                              alignItems="end">
    <TablePagination
        component="div"
        count={props.totalElements}
        page={props.page}
        onPageChange={(event, value) => props.onChange(value)}
        rowsPerPage={props.size}
        onRowsPerPageChange={(event) => {
            props.onRowsChange(parseInt(event.target.value, 10));
        }}
    />
</Stack>
