import React from "react";
import {WaveformViewFuncComponent} from "../../../UI/WaveformViewFuncComponent";

export const TranscriptionFileFooter = ({
                                            transcription,
                                            peaks,
                                            position,
                                            duration,
                                            setPosition,
                                            wordPosition,
                                            followText,
                                            setFollowText
                                        }) => {

    return (
        <div className={"transcription-footer bg-light text-dark px-2 mb-0 row mt-auto border-top"}>
            {
                transcription ?
                    <>
                        <div className={"col-12"}>
                            <WaveformViewFuncComponent
                                audioUrl={`${process.env.REACT_APP_GATEWAY}api/file/audio/${transcription?.audioMP3File?.id || transcription?.audioFile?.id}`}
                                audioContentType={'audio/wav'}
                                waveformDataUrl={`${process.env.REACT_APP_GATEWAY}api/file-transcription/peaks/${transcription?.id}`}
                                followText={followText}
                                setFollowText={setFollowText}
                                setPosition={setPosition}
                                position={position}
                                wordPosition={wordPosition}
                                transcription={transcription}
                                setSegments={() => {}}
                                setPoints={() => {}}
                            />
                        </div>
                    </> :
                    <h4 className={"text-white py-3"}>Аудио фајлот се конвертира во mp3 ве молиме почекајте...</h4>
            }
        </div>

    )
}