import SweetAlert from "react-bootstrap-sweetalert";

export const confirmDialogOptions = {
    render: (message, onConfirm, onCancel) => {
        return (
            <SweetAlert onConfirm={onConfirm}
                        customButtons={
                            <>
                                <button type={"button"} className="btn btn-outline-secondary m-3"
                                        onClick={onCancel}>Откажи
                                </button>
                                <button type={"button"} className="btn btn-danger m-3" onClick={onConfirm}>Потврди</button>
                            </>
                        }
                        warning
                        show={true}>
                <h4>{message}</h4>
            </SweetAlert>
        );
    }
}