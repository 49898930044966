import React, {useContext} from 'react';
import {useHistory} from "react-router-dom";
import {AuthContext} from "../../context/auth-context";
import {BiLogIn, BiUserPlus} from "react-icons/bi";
import {RegisterTab} from "./RegisterTab";
import {LoginTab} from "./LoginTab";

function LandingPage() {
    return (
        <div className={"landing-page"} style={{height: "100vh"}}>
            <div className="container pt-5 h-100">
                <div className="row display-flex h-100">
                    {/*<div className="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 my-auto">*/}
                    {/*    <div className="landing-content text-white ">*/}
                    {/*        <h2>Агенција за аудио и аудиовизуелни медиумски услуги</h2>*/}
                    {/*        <h6>Агенција за аудио и аудиовизуелни медиумски услуги ги преслушува сите радио станици во Макеоднија, со цел откривање на несоодветни искажувања на говор на омраза.</h6>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto">

                        <div className="rounded bg-white p-3 shadow">
                            <LoginTab />
                        </div>
                        <p className={"text-center py-3"}>© 2022 iReason LLC, All rights reserved.</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LandingPage;