import axios from "../axios/axios";

export const TranscriptionRepository = {
    getAllPaged: (page, size, search,category) => {
        const params = {
            page: page,
            size: size,
        }
        if (search) {
            params.search = search
        }
        if (category) {
            params.category = category
        }
        return axios.get(`/api/file-transcription`, {
            params: {...params}
        });
    },
    createTranscriptionFile: (id, request, options) => {
        return axios.post(`/api/file-transcription/create/${id}`, request, options)
    },
    preCreateTranscriptionFile: (name) => {
        return axios.post(`/api/file-transcription/pre-create`, name)
    },
    getTranscription: (id) => {
        return axios.get(`/api/file-transcription/${id}`)
    },
    getPeaks: (id) => {
        return axios.get(`/api/file-transcription/peaks/${id}`)
    },
    deleteTranscription: (id) => {
        return axios.delete(`/api/file-transcription/${id}`)
    }
}