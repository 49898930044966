import React from "react";
import {Spinner} from "./Spinner";


export const LoadingPage = () => {

    return (
        <div className={"landing-page"}>
            <div className={"spinner"}>
                <Spinner/>
            </div>
        </div>
    )
}