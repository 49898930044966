import React, {useContext, useState} from "react";
import {AuthContext} from "../../context/auth-context";
import {Link, useHistory} from "react-router-dom";
import {UserRepository} from "../../repository/UserRepository";
import {toast} from "react-toastify";
import {TextField} from "@material-ui/core";

export const LoginTab = () => {
    const [user, setUser] = useState({
        username: "",
        password: ""
    })
    const [error, setError] = useState({status: false, message: ""})
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const login = (event) => {
        event.preventDefault()
        if (user.username && user.password) {
            UserRepository.login(user.username, user.password)
                .then(response => {
                    authContext.login(response.data)
                    history.push("/");
                })
                .catch(function (error) {
                    toast.error("Не успешна најава.")
                    if (error.response !== undefined && error.response.status !== undefined && error.response.status === 403) {
                        setError({status: true, message: "Invalid credentials"});
                    }
                })
        }
    }

    return (
        <div className="tab-pane fade show active text-secondary" id="profile" role="tabpanel"
             aria-labelledby="profile-tab">
            <div className={"text-center m-4"}>
                <img src={"/img/logo.png"} width={"200px"} height={"200px"} />
            </div>
            <form className="content" onSubmit={login}>
                <div className="row">
                    <div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
                        <div className="form-floating mb-3">
                            <TextField
                                type={"text"}
                                id={"username"}
                                name={"username"}
                                fullWidth
                                value={user.username}
                                onChange={event => setUser(prevState => {
                                    return {
                                        ...prevState,
                                        username: event.target.value
                                    }
                                })}
                                label="Корисничко име"
                                variant="standard" />
                        </div>
                         <div className="form-floating mb-3">
                             <TextField
                                 type={"password"}
                                 id="password"
                                 name={"password"}
                                 fullWidth
                                 value={user.password}
                                 onChange={event => setUser(prevState => {
                                     return {
                                         ...prevState,
                                         password: event.target.value
                                     }
                                 })}
                                 label="Лозинка"
                                 variant="standard" />
                         </div>
                        <button type={"submit"}
                                disabled={!user.username && !user.password}
                                className="btn btn-success w-100 mb-3">Најави се</button>

                        <div className="text-start ">
                            <Link to={"/forgot-password"} className="forgot text-start py-2 text-secondary">Ја заборавив
                                лозинката</Link>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}