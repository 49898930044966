import React, {useState} from 'react';
import {UserRepository} from "../../repository/UserRepository";

import {toast} from "react-toastify";
import {Link} from "react-router-dom";

export const ForgotPassword = () => {
    const [email, setEmail] = useState("");

    const forgotPasswordHandle = (event) => {
        event.preventDefault();
        if (email) {
            UserRepository.forgotPassword(email)
                .then(response => {
                    setEmail("")
                    toast.success("Успешно е поднесено барање, проверете ја вашата емаил адреса за да ја променете лозинката.");
                })
                .catch(error => {
                    toast.error("Не успешно поднесено барање, обидете се повторно.");
                })
        }
    }

    return (
        <div className={"landing-page h-100"} style={{background: "#323445"}}>
            <div className="container pt-5 h-100">
                <div className="row display-flex h-100">
                    <div className="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 my-auto">
                        <div className="landing-content text-white ">
                            <h2>Транскрипција.мк ви овозможува брза и едноставна онлајн конверзија на вашите говорни
                                аудио документи во текст.</h2>
                            <h6>Транскрипцијата ја добивате во текстуален документ.</h6>
                        </div>
                    </div>

                    <div className="col col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto">
                        <div className="registration-login-form rounded text-secondary bg-white p-0">
                            <div className="tab-content p-3" id="registration-form-tabs-content">
                                <div>
                                    <h5 className="py-3 border-bottom">Заборавена лозинка</h5>
                                    <form className="content" onSubmit={forgotPasswordHandle}>
                                        <div className="row">
                                            <div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-floating mb-3">
                                                    <input className="form-control"
                                                           id={"email"}
                                                           value={email}
                                                           onChange={event => setEmail(event.target.value)}
                                                           placeholder="Емаил адреса"
                                                           type="email"/>
                                                    <label htmlFor={"email"}>Емаил адреса</label>
                                                </div>

                                                <button type={"submit"}
                                                        className="btn btn-lg btn-primary w-100 mb-3">Поднеси барање
                                                </button>
                                                <div className="text-start mb-3">
                                                    <Link to={"/login"}
                                                          className="forgot text-start py-2 text-secondary">
                                                        Најави се
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
