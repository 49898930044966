import React, {useState} from "react";
import {FaUser} from "react-icons/fa";
import {convertHMS} from "./ConvertToHMS";

const ChatPosition = {
    LEFT: "LEFT",
    RIGHT: "RIGHT"
}

export const Chat = ({onClick, onTextItemDoubleClick, onSpeakerDoubleClick, position, textItems, search}) => {


    const mapItems = () => {
        let lastPosition = ChatPosition.RIGHT;
        let speakerAnonymousCounter = {};
        let speakers = {};

        return textItems.map(textItem => {
            let start = textItem.words[0]?.start;
            let end = textItem.words[textItem.words.length - 1]?.end;
            start = start ? start / 1000 : null;
            end = end ? end / 1000 : null;

            let speaker = textItem.speaker;
            if (speaker && speakers[speaker.id]) {
                speaker = speakers[speaker.id];
            } else {
                speaker = speaker === null ? {id: -1} : speaker
                lastPosition = lastPosition === ChatPosition.RIGHT ? ChatPosition.LEFT : ChatPosition.RIGHT;

                speaker.position = lastPosition;
                let name = speaker?.name;
                if (name === null) {
                    name = `Говорник ${Object.keys(speakerAnonymousCounter).length + 1}`;
                    speakerAnonymousCounter[speaker.id] = true;
                }
                speaker.name = name;
                speakers[speaker?.id] = speaker;
            }

            return <div
                key={textItem.id}
                id={`text-${textItem.id}`}
                title={`${textItem.label} - ${textItem.score.toFixed(2)}`}
                className={"d-block my-2"}>
                <div className={`d-inline-flex w-100 ${speaker.position === ChatPosition.RIGHT ? "flex-row-reverse ms-auto" : ""}`}>
                    <div className={"my-auto"}>
                        <div id="user-initials-image"
                             onClick={() => onSpeakerDoubleClick(textItem)}
                             title={speaker?.name || "Додадете говорник"}
                             className={`adf-userinfo-pic mx-2 pointer ${speaker.position === ChatPosition.RIGHT ? "bg-primary" : "bg-secondary"} bg-opacity-25`}>
                            {
                                speaker?.name?.split(" ").reduce((prevValue, nextValue) => prevValue + nextValue[0], "").substr(0, 2) || "X"
                            }
                        </div>
                        <div className={"text-center"}>
                            <small className={`small text-secondary text-center ${speaker.position === ChatPosition.RIGHT ? "text-end" : ""}`}>{convertHMS(start)}</small>
                        </div>
                    </div>
                    <div>
                        <p className={`rounded-4 ${textItem.label === "Hate" ? "bg-danger" : speaker.position === ChatPosition.RIGHT ? "bg-primary" : "bg-secondary"} bg-opacity-25 py-2 px-3 mb-0`} onDoubleClick={() => onTextItemDoubleClick(textItem)}>
                            {
                                textItem?.words?.map(word => {
                                    return <span
                                        className={
                                            `word ${position > start && position <= end ? "fw-bold" : ""} ${search && textItem.text.includes(search) ? 'bg-warning bg-opacity-25' : ''} ${textItem.label === 'Hate' ? 'word-insult border-danger ' : ''}`
                                        }
                                        onClick={() => onClick(word)}
                                        title={`${(word.start / 1000)}s-${(word.end / 1000)}s`}>{word.text} </span>

                                })
                            }
                        </p>
                    </div>
                    <div className={"my-auto"}>
                        <div id="user-initials-image" className={`adf-userinfo-pic mx-2 pointer bg-transparent`}/>
                    </div>
                </div>
            </div>
        })
    }

    return <>
        {
            textItems && mapItems()
        }
    </>
}